import React, { useState, useEffect } from "react";
import "../Pages/Style.css";
import  logo from "../Images/logo.png"

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Change the value (e.g., 100) to the desired scroll position where you want to update the header
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`navbar navbar-expand-lg ${isScrolled ? "scrolled" : ""}`}>

<div className="container-fluid">
<div className="header_nabe">
<div className="header_logo">
      <img src={require("../Images/logo.png")} />
      </div>
      <div className="socila_icon">
        <ul>

        <li><a href=" https://wa.me/8056786622" target="_blank"><i class="ri-whatsapp-fill"></i></a></li>
        <li><a href="https://t.me/BeleafSoftTech" target="_blank"><i class="ri-telegram-fill"></i></a></li>

          <li><a href="https://join.skype.com/invite/wg5IW8g4Uaca" target="_blank"><i class="ri-skype-fill"></i></a></li>
          <li><a href="mailto:business@beleaftechnologies.com" target="_blank"><i class="ri-mail-fill"></i></a></li>

        </ul>
      </div>
</div>
</div>

      
      




      

      </nav>
    </>
  );
}

import config from './key';

import axios from 'axios';

export const sendMessage = async (data) => {
    console.log(data,"sendmessage")
    try {
        let respData = await axios({
            'method': 'post',
            'url': config.key.API_URL + data.apiUrl,
            'headers':{'Content-Type': 'application/json'},
            'data': data
        });
        return respData.data;
    } catch (error) {
        console.log(error)
        var data = {status:"false"}
        return data;

    }
}




import React from "react";
import { useState, useRef } from "react";
import { sendMessage } from "../config/apiCall";
import httpService from "../config/httpcal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Footer() {
  const initiaformvalue = {
    name: "",
    phonenumber: "",
    email: "",
    message: "",
  };
  const [formvalue, setFormvalue] = useState(initiaformvalue);
  const [vallidation, setvallidation] = useState("");
  const [nameErr, setNameErr, nameErrref] = useState(false);
  const [phonenumberErr, setphonenumberErr, phonenumberErrref] =
    useState(false);
  const [emailErr, setemailErr, emailErrref] = useState(false);
  const [messageErr, setmessageErr, messageErrref] = useState(false);
  const { name, phonenumber, email, message } = formvalue;

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formvalue, ...{ [name]: value } };
    setFormvalue(formData);
  };

  const vallidate = () => {
    try {
      var error = {};

      if (name == "") {
        setNameErr(true);
        error.name = "Field cannot be blank";
      } else {
        setNameErr(false);
      }
      if (phonenumber == "") {
        setphonenumberErr(true);
        error.phonenumber = "Field cannot be blank";
      } else {
        setphonenumberErr(false);
      }
      if (email == "") {
        setemailErr(true);
        error.email = "Field cannot be blank";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setemailErr(true);
        error.email = "Invalid Email";
      } else {
        setemailErr(false);
      }
      if (message == "") {
        setmessageErr(true);
        error.message = "Field cannot be blank";
      } else {
        setmessageErr(false);
      }

      setvallidation(error);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const submitform = async (e) => {
    e.preventDefault();
    try {
      vallidate();
      if (
       formvalue.name  != "" &&
       formvalue.message  != "" &&
       formvalue.email  != "" &&
       formvalue.phonenumber  != ""
      ) {
        console.log("llkljdflkdsjalkfjklsadjfsafkjklsadjflk")
        formvalue["type"] = "portfolio";
        var obj = {
          apiUrl: httpService.sendMessage,
          payload: formvalue,
        };
        var resp = await sendMessage(obj);
        console.log(resp,"-0-0-0-0-0-0-0-0-")
        if(resp.status== true){
          toast.success(resp.Message)

        }else{
          toast.error(resp.Message)

        }
      }else{
        console.log("-0-0-0-0-0-0-0-0-")

      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      <section className="get-change" id="contact">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-12">
              <div className="gettouch">
                <h4 className="getaccess">Get In Touch</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-us">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-10 ">
              <div className="row form-row">
                <div className="col-lg-6">
                  <form>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your Name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                      ></input>
                      {nameErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.name}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Phone</label>
                      <input
                        type="number"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="+91"
                        name="phonenumber"
                        value={phonenumber}
                        onChange={handleChange}
                      ></input>
                      {phonenumberErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.phonenumber}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
                <div className="col-lg-6">
                  <form>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      ></input>
                      {emailErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.email}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Message</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter Your Message"
                        name="message"
                        value={message}
                        onChange={handleChange}
                      ></input>
                      {messageErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.message}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
                <div className="col-lg-12">
                  <div className="send-Message">
                    <div className="d-flex justify-content-center mt-5">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={submitform}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-section">
        <footer>
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-3">
                  <div class="footer-base">
                    <img src={require("../Images/logo.png")} />
                  </div>
                </div>

                <div class="col-md-3">
                  <ul class="address1">
                    <li>
                      <i class="ri-map-pin-line"></i>
                      5/24,Bharathiyar 7th Street,
                      S.SColony,Madurai,Tamil Nadu 625016
                    </li>
                  </ul>
                </div>
                <div class="col-md-3">
                  <ul class="address1">
                    <li>
                      <i class="ri-map-pin-line"></i>12, 4th floor Krishnasthal
                      CHS, Mira Gaon Mira Road East,Thane, Maharastra. Postal
                      Code - 401107 India.
                    </li>
                  </ul>
                </div>

                <div class="col-md-3">
                  <h4>Social</h4>
                  <ul class="social-icon">
                    <li>
                      <a href="https://www.facebook.com/Beleaf-Technologies-115471241167315" target="_blank">
                        <i
                          class="ri-facebook-circle-fill"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://x.com/BeleafSoftTech" target="_blank">
                        <i class="ri-twitter-fill" aria-hidden="true"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://pin.it/3bOarDn" target="_blank">
                        <i class="ri-pinterest-fill" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/beleaftechnologies/" target="_blank">
                        <i class="ri-instagram-line" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://join.skype.com/invite/wg5IW8g4Uaca" target="_blank">
                        <i class="ri-skype-fill" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/BeleafSoftTech" target="_blank">
                        <i class="ri-telegram-fill" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/beleaf-technologies-blf/" target="_blank">
                        <i class="ri-linkedin-fill" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <p class="copyright text-uppercase">
                    © 2024 Beleaf Soft Tech Solutions All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>

      <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog modal-lg popup_ik modal-dialog-centered">
          <div class="modal-content bg_ik ">
            <div className="col-lg-12">
              <div class="modal-header">
                <h4 class="modal-title ">Contact us now</h4>
                <button
                  type="button"
                  class="close close close_ik"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
            </div>
            <div class="modal-body">
              <form className="row form_ik">
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="name">Name:</label>
                    <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your Name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                      ></input>
                      {nameErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.name}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="email">Email:</label>
                    <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      ></input>
                      {emailErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.email}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="number">Mobile Number:</label>
                    <input
                        type="number"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="+91"
                        name="phonenumber"
                        value={phonenumber}
                        onChange={handleChange}
                      ></input>
                      {phonenumberErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.phonenumber}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="email">Message:</label>
                    <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter Your Message"
                        name="message"
                        value={message}
                        onChange={handleChange}
                      ></input>
                      {messageErr == true ? (
                        <span className="text-danger">
                          {" "}
                          {vallidation.message}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                  </div>
                </div>

                <div className="col-lg-12">
                  <button class="button_ik" onClick={submitform} >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

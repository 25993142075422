import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "../Pages/Style.css";
import { useInView } from "react-intersection-observer";
// import video from '../videos/pexelsvideos.mp4'

export default function Home() {
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext(); // Advance to the next slide
    }
  }, [isScrolled]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext(); // Advance to the next slide
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);
  return (
    <>
      <Header />
      <section className="home-new banner_height">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero">
                <div className="hero-content">
                  <div className="hero-title1">
                    <h1>
                      Welcome to <br />
                      Beleaf Soft Tech Solutions
                    </h1>
                    <p>
                      We are a leading technology company that specializes in
                      delivering exceptional digital solutions. With a
                      commitment to innovation and client satisfaction, we have
                      established ourselves as a trusted partner for businesses
                      of all sizes. From startups to established enterprises, we
                      have the expertise and creativity to bring your ideas to
                      life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        {/* 
      <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero">
                <div className="hero-content">
                  <div className="hero-title1">
                    <h2>Explore <br/>Our Portfolio</h2>
                    <p>Visit our website to explore our impressive portfolio of completed projects. From visually stunning websites to feature-rich applications, our work showcases our expertise and creativity. We have successfully collaborated with clients from various industries. Take a glimpse into our portfolio and witness the diversity and quality of our work.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

         
          
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero">
                <div className="hero-content">
                  <div className="hero-title1">
                    <h2>
                      Our excellent blockchain project is booming in the market
                      now.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <a
                href="https://cashbit.io/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-cash.png")} />
                </div>
                <div className="content_section">
                  <p>Cashbit</p>
                </div>
              </a>
            </div>

            <div className="col-lg-6">
              <a
                href="https://cex.beleaftechnologies.com/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-sellcrypt.png")} />
                </div>
                <div className="content_section">
                  <p>Sellcrypt</p>
                </div>
              </a>
            </div>

            <div className="col-lg-6">
              <a
                href="https://bitbuyerx.beleaftechnologies.com/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-bit.png")} />
                </div>
                <div className="content_section">
                  <p>Bitbuyerx</p>
                </div>
              </a>
            </div>

            <div className="col-lg-6">
              <a
                href="https://mintingx.cloud/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-mintx.png")} />
                </div>
                <div className="content_section">
                  <p>Mintingx</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://artverse.store/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-art.png")} />
                </div>
                <div className="content_section">
                  <p>Artverse</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://arbitrex.club/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-arbit.png")} />
                </div>
                <div className="content_section">
                  <p>Arbitrex</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://solalgo.com/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-solal.png")} />
                </div>
                <div className="content_section">
                  <p>Solalgo</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://shatri.org/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-shatri.png")} />
                </div>
                <div className="content_section">
                  <p>Shatri</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://kdxdex.online"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-kdxdex.png")} />
                </div>
                <div className="content_section">
                  <p>Kdxdex</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://yarlconnect.site/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-yarl.png")} />
                </div>
                <div className="content_section">
                  <p>Yarlconnect</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://swapo.shop"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav.png")} />
                </div>
                <div className="content_section">
                  <p>Swapo</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://mxmlm.site/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-mlmxm.png")} />
                </div>
                <div className="content_section">
                  <p>Mxmlm</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://gatecryptopay.store/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-yarl-1.png")} />
                </div>
                <div className="content_section">
                  <p>Gatecryptopay</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://coingamex.cloud/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-coin.png")} />
                </div>
                <div className="content_section">
                  <p>Coingamex</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://swapo.shop"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-cross.png")} />
                </div>
                <div className="content_section">
                  <p>Crosscrypto</p>
                </div>
              </a>
            </div>

            {/* <div className="col-lg-6">
              <a
                href="https://skgcryparb.com/home"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-prime.png")} />
                </div>
                <div className="content_section">
                  <p>Skgcryparb</p>
                </div>
              </a>
            </div>
            <div className="col-lg-6">
              <a
                href="https://taikonz.io/"
                className="flex_ahnertag mb-3"
                target="_blank"
              >
                <div className="image_fileds">
                  <img src={require("../Images/logos/fav-taikonz.png")} />
                </div>
                <div className="content_section">
                  <p>Taikonz</p>
                </div>
              </a>
            </div> */}
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="hero">
              <div className="hero-content">
                <div className="hero-title1">
                  <h2>The quick glimpse of our excellent work</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="category">
              <ul class="nav nav-tabs">
                <li>
                  <a data-toggle="tab" href="#Blockchain" className="active">
                    CEX
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#dex">
                    DEX & DeFi
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#Bot">
                    Bot
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#Payment">
                    Crypto Payment
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#Web">
                    NFT
                  </a>
                </li>

                <li>
                  <a data-toggle="tab" href="#gaming">
                    Gaming
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#ico">
                    ICO
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#own-block">
                    Own Blockchain
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#mobile">
                    Mobile App
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#mlm">
                    MLM
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#media">
                    Media & Graphics
                  </a>
                </li>
              </ul>

              <div class="tab-content" id="pills-tabContent">
                <div id="Blockchain" class="tab-pane active show">
                  <div className="row justify-content-center">


                  <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide3">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_1.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_2.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_3.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_4.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_5.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_6.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_7.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_8.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_9.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/sellcrypt/sellcrypt_10.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Sellcrypt</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide3">
                              <img
                                src={require("../Images/cashbit/cashbit1.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/cashbit/cashbit2.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/cashbit/cashbit3.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/cashbit/cashbit4.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/cashbit/cashbit5.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/cashbit/cashbit6.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/cashbit/cashbit7.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/cashbit/cashbit8.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Cashbit</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide3">
                              <img
                                src={require("../Images/bitbuyerx/landingpage.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/bitbuyerx/trade.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/bitbuyerx/p2p.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/bitbuyerx/staking.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/bitbuyerx/launchpad.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/bitbuyerx/swap.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/bitbuyerx/giftcard.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/bitbuyerx/postad.png")}
                              />
                            </div>
                            <div className="slide12">
                              <img
                                src={require("../Images/bitbuyerx/portfolio.png")}
                              />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/bitbuyerx/p2pwallet.png")}
                              />
                            </div>
                            <div className="slide14">
                              <img
                                src={require("../Images/bitbuyerx/referral.png")}
                              />
                            </div>
                            <div className="slide15">
                              <img
                                src={require("../Images/bitbuyerx/profile.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Bitbuyerx</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/adverb/landing.png")}
                              />
                            </div>
                            <div className="slide2">
                              <img
                                src={require("../Images/adverb/landing1.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/adverb/landing2.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/adverb/login.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/adverb/dashboard.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/adverb/deposit.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/adverb/withdraw.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/adverb/trade1.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/adverb/trade2.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img src={require("../Images/adverb/swap.png")} />
                            </div>
                            <div className="slide12">
                              <img
                                src={require("../Images/adverb/referral.png")}
                              />
                            </div>
                            <div className="slide13">
                              <img src={require("../Images/adverb/p2p.png")} />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/adverb/postAd.png")}
                              />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/adverb/staking.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Adverb</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/justbit/landing.png")}
                              />
                            </div>
                            <div className="slide2">
                              <img
                                src={require("../Images/justbit/landing1.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/justbit/landing3.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/justbit/login.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img src={require("../Images/justbit/kyc.png")} />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/justbit/deposit.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/justbit/history.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/justbit/trade.png")}
                              />
                            </div>
                            {/* <div className="slide10">
                                <img
                                  src={require("../Images/justbit/deposit7.png")}
                                />
                              </div>
                              <div className="slide11">
                                <img
                                  src={require("../Images/justbit/fiatDepoist10.png")}
                                />
                              </div>
                              <div className="slide12">
                                <img
                                  src={require("../Images/justbit/history11.png")}
                                />
                              </div>
                              <div className="slide13">
                                <img
                                  src={require("../Images/justbit/kyc3.png")}
                                />
                              </div>
                              <div className="slide13">
                                <img
                                  src={require("../Images/justbit/trade15.png")}
                                />
                              </div>
                              <div className="slide13">
                                <img
                                  src={require("../Images/justbit/transfer9.png")}
                                />
                              </div>
                              <div className="slide13">
                                <img
                                  src={require("../Images/justbit/withdraw8.png")}
                                />
                              </div> */}
                          </Slider>
                          <p className="Header">Justbit</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/farmcex/Dashboard4.png")}
                              />
                            </div>
                            <div className="slide2">
                              <img
                                src={require("../Images/farmcex/buycrypto7.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/farmcex/coinlist10.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/farmcex/deposit8.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/farmcex/history11 (1).png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/farmcex/kyc6.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/farmcex/land1.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/farmcex/log3.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/farmcex/profile5.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/farmcex/reg2.png")}
                              />
                            </div>
                            <div className="slide12">
                              <img
                                src={require("../Images/farmcex/security15.png")}
                              />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/farmcex/support12 (1).png")}
                              />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/farmcex/trade13.png")}
                              />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/farmcex/tradingView14.png")}
                              />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/farmcex/withdraw9.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Farmcex</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/addcrypto/addcrypto1.png")}
                              />
                            </div>
                            <div className="slide2">
                              <img
                                src={require("../Images/addcrypto/addcrypto2.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/addcrypto/addcrypto3.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/addcrypto/addcrypto4.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/addcrypto/addcrypto5.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/addcrypto/addcrypto6.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/addcrypto/addcrypto8.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/addcrypto/addcrypto9.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/addcrypto/addcrypto10.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Addcrypto</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/encrypto/encrypto1.png")}
                              />
                            </div>
                            <div className="slide2">
                              <img
                                src={require("../Images/encrypto/encrypto2.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/encrypto/encrypto3.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/encrypto/encrypto4.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/encrypto/encrypto5.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/encrypto/encrypto6.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/encrypto/encrypto7.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/encrypto/encrypto8.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/encrypto/encrypto9.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/encrypto/encrypto10.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Encrypto</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-6 align_center">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/taiko/Screenshot1.png")}
                              />
                            </div>
                            <div className="slide2">
                              <img
                                src={require("../Images/taiko/Screenshot 2.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/taiko/Screenshot3.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/taiko/Screenshot4.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/taiko/Screenshot6.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/taiko/Screenshot 7.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/taiko/Screenshot 8.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/taiko/Screenshot 9.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/taiko/Screenshot 10.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/taiko/Screenshot 11.png")}
                              />
                            </div>
                            <div className="slide12">
                              <img
                                src={require("../Images/taiko/landing1 (1).png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Taikonz</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div id="dex" class="tab-pane">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/about(pic)/Connect45.png")}
                              />
                            </div>
                            <div className="slide2">
                              <img
                                src={require("../Images/about(pic)/dashboard.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/about(pic)/deposit9.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/about(pic)/depositHis6.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/about(pic)/landingPage1.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/about(pic)/metamastk2.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/about(pic)/multichain8.png")}
                              />
                            </div>
                            <div className="slide9">
                              <img
                                src={require("../Images/about(pic)/Trade5.png")}
                              />
                            </div>
                            <div className="slide10">
                              <img
                                src={require("../Images/about(pic)/tradeWhite10.png")}
                              />
                            </div>
                            <div className="slide11">
                              <img
                                src={require("../Images/about(pic)/whitetheme9.png")}
                              />
                            </div>
                            <div className="slide12">
                              <img
                                src={require("../Images/about(pic)/withdraw4.png")}
                              />
                            </div>
                            <div className="slide13">
                              <img
                                src={require("../Images/about(pic)/withdrawHis7.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">KeeDex</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <a
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img src={require("../Images/Defi/1.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/Defi/2.png")} />
                            </div>
                          </Slider>
                          <p className="Header">Yarloo</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="Web" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/nftdemo/landing1.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/nftdemo/landing2.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/nftdemo/landing3.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/nftdemo/landing4.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/nftdemo/landing5.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/nftdemo/explore.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/nftdemo/marketplace.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/nftdemo/mint_nft.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/nftdemo/profile.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">NFT</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/xverse/landing1.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/xverse/landing2.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/xverse/landing3.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/xverse/explore.png")}
                              />
                            </div>
                            <div className="slide5">
                              <img src={require("../Images/xverse/faq.png")} />
                            </div>

                            <div className="slide5">
                              <img
                                src={require("../Images/xverse/mint_nft.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/xverse/mint_nft1.png")}
                              />
                            </div>
                            <div className="slide5">
                              <img
                                src={require("../Images/xverse/mint_nft2.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Xverse</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/fanticallydemo/landing.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/fanticallydemo/landing1.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/fanticallydemo/login.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/fanticallydemo/editprofile.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/fanticallydemo/profile.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/fanticallydemo/explore.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/fanticallydemo/mint_nft.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Fantically</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img src={require("../Images/nft/loud/1.png")} />
                            </div>

                            <div className="slide2">
                              <img src={require("../Images/nft/loud/2.png")} />
                            </div>
                          </Slider>
                          <p className="Header">loud</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="ico" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/solalgo/solalgo1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/solalgo/solalgo2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/solalgo/solalgo3.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/solalgo/solalgo4.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/solalgo/solalgo5.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/solalgo/solalgo6.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/solalgo/solalgo7.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Solalgo</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/chola/chola1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/chola/chola2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/chola/chola3.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/chola/chola4.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/chola/chola5.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/chola/chola6.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Chola Token</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/ico/1poolfinance.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">1Poolfinance</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img src={require("../Images/ico/Bitvix.png")} />
                            </div>
                          </Slider>
                          <p className="Header">Bitvix</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/ico/cbaitoken.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Cbaitoken</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/ico/myhomecoin.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Myhomecoin</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/ico/dexberry.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Dexberry</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/ico/tbttoken.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Tbttoken</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/ico/kojucoin.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Kojucoin</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/ico/entireswap.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Entireswap</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="own-block" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/blockchain/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/blockchain/2.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Maalscan</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="mlm" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/reefchain/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/reefchain/2.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Reefchain</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tron360/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tron360/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tron360/3.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Tron360</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronalexa/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronalexa/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronalexa/3.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Tronalexa</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronchain/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronchain/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronchain/3.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Tronchain</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronhero/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronhero/1.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Tronhero</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronwhirlwind/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronwhirlwind/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronwhirlwind/3.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Tronwhirlwind</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronxp/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronxp/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronxp/3.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Tronxp</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronzen/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronzen/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronzen/3.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/mlm/tronzen/4.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">tronzen</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="gaming" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/bcgames/game1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/bcgames/game2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/bcgames/game3.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/bcgames/game4.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/bcgames/game5.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">BC.GAME Clone</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img src={require("../Images/games/game1.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game2.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game3.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game4.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game5.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game6.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game7.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game8.png")} />
                            </div>
                            <div className="slide1">
                              <img src={require("../Images/games/game9.png")} />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/games/game10.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/games/game11.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/games/game12.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">SPLINTERLANDS Clone</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/lott3/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/lott3/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/lott3/3.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/lott3/4.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Lott3</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/lott3Love/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/lott3Love/2.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Lott3Love</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/zoomfi/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/zoomfi/2.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/game/zoomfi/3.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Zomfi</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="mobile" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mobile/farmcex/1.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Farmcex</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mobile/papennyx/1.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Papennyx</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mobile/taikonz/1.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Taikonz</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* cashbit & fabric */}
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/switch/switch1.jpg")}
                              />
                            </div>

                            <div className="slide1">
                              <img
                                src={require("../Images/switch/switch2.jpg")}
                              />
                            </div>

                            <div className="slide1">
                              <img
                                src={require("../Images/switch/switch3.jpg")}
                              />
                            </div>

                            <div className="slide1">
                              <img
                                src={require("../Images/switch/switch4.jpg")}
                              />
                            </div>

                            <div className="slide1">
                              <img
                                src={require("../Images/switch/switch5.jpg")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Cashbit</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/fabric/fabric1.png")}
                              />
                            </div>

                            <div className="slide1">
                              <img
                                src={require("../Images/fabric/fabric2.png")}
                              />
                            </div>

                            <div className="slide1">
                              <img
                                src={require("../Images/fabric/fabric3.png")}
                              />
                            </div>

                            <div className="slide1">
                              <img
                                src={require("../Images/fabric/fabric4.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Fabric</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="media" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/digital art/1.png")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/digital art/2.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/digital art/3.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/digital art/4.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/digital art/5.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/digital art/6.jpg")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Digital art</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/logo/1.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/logo/2.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/logo/3.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/logo/4.jpg")}
                              />
                            </div>
                            <div className="slide1">
                              <img
                                src={require("../Images/media and graphics/logo/5.jpg")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Logo</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Bot" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide2">
                              <img
                                src={require("../Images/summitclub/club2.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/summitclub/club3.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/summitclub/club4.png")}
                              />
                            </div>
                            <div className="slide5">
                              <img
                                src={require("../Images/summitclub/club5.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/summitclub/club6.png")}
                              />
                            </div>
                            <div className="slide6">
                              <img
                                src={require("../Images/summitclub/club7.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/summitclub/club8.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Summit Club</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/arbitrage_bot/landing.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/arbitrage_bot/landing1.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/arbitrage_bot/login.png")}
                              />
                            </div>

                            <div className="slide6">
                              <img
                                src={require("../Images/arbitrage_bot/bot_find.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/arbitrage_bot/exchange.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/arbitrage_bot/pair.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/arbitrage_bot/botconfig.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/arbitrage_bot/apikey.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/arbitrage_bot/botprofit.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Arbitrage Bot</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/mmbot/landing.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/mmbot/landing1.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/mmbot/botconfig.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/mmbot/orderbook.png")}
                              />
                            </div>
                            <div className="slide5">
                              <img src={require("../Images/mmbot/logs.png")} />
                            </div>
                          </Slider>
                          <p className="Header">Market Making Bot</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Payment" class="tab-pane ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="cardbanner">
                        <div className="card-up">
                          <Slider {...settings} ref={sliderRef}>
                            <div className="slide1">
                              <img
                                src={require("../Images/paymentgateway/landing.png")}
                              />
                            </div>

                            <div className="slide2">
                              <img
                                src={require("../Images/paymentgateway/landing1.png")}
                              />
                            </div>
                            <div className="slide3">
                              <img
                                src={require("../Images/paymentgateway/landing2.png")}
                              />
                            </div>

                            <div className="slide6">
                              <img
                                src={require("../Images/paymentgateway/landing3.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/paymentgateway/profile.png")}
                              />
                            </div>
                            <div className="slide4">
                              <img
                                src={require("../Images/paymentgateway/dashboard.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/paymentgateway/business_verify.png")}
                              />
                            </div>
                            <div className="slide7">
                              <img
                                src={require("../Images/paymentgateway/deposit.png")}
                              />
                            </div>
                            <div className="slide8">
                              <img
                                src={require("../Images/paymentgateway/checkouts.png")}
                              />
                            </div>

                            <div className="slide8">
                              <img
                                src={require("../Images/paymentgateway/invoice_create.png")}
                              />
                            </div>

                            <div className="slide8">
                              <img
                                src={require("../Images/paymentgateway/script.png")}
                              />
                            </div>

                            <div className="slide8">
                              <img
                                src={require("../Images/paymentgateway/widgets.png")}
                              />
                            </div>
                          </Slider>
                          <p className="Header">Crypto Payment</p>

                          <div class="banner-button">
                            <div className="col-lg-12">
                              <button
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="home-new ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero">
                <div className="hero-content">
                  <div className="hero-title1">
                    <h2>
                      Why Choose <br /> Beleaf Soft Tech Solutions?
                    </h2>
                    <p>
                      We are a leading technology company that specializes in
                      delivering exceptional digital solutions. With a
                      commitment to innovation and client satisfaction, we have
                      established ourselves as a trusted partner for businesses
                      of all sizes. From startups to established enterprises, we
                      have the expertise and creativity to bring your ideas to
                      life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card_why">
                  <div className="icon">
                    <i class="ri-tools-fill"></i>
                  </div>
                  <h3>Cutting-Edge Solutions</h3>
                  <p>
                    At Beleaf Soft Tech Solutions, we stay at the forefront of
                    technological advancements. Our team of experts leverages
                    the latest tools and technologies to develop customized
                    solutions that address your unique business needs. Whether
                    you require web development, mobile applications, or
                    software solutions, we have the skills to create powerful
                    and scalable products.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_why">
                  <div className="icon">
                    <i class="ri-tools-fill"></i>
                  </div>
                  <h3>Tailored Approach</h3>
                  <p>
                    We understand that every business is unique. That's why we
                    take a personalized approach to our projects. Our team works
                    closely with you to gain a deep understanding of your goals
                    and challenges. We then design and develop solutions that
                    are tailor-made for your business, helping you stand out
                    from the competition.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_why">
                  <div className="icon">
                    <i class="ri-tools-fill"></i>
                  </div>
                  <h3>Quality Assurance</h3>
                  <p>
                    Quality is our utmost priority. We adhere to industry best
                    practices and conduct rigorous testing throughout the
                    development process. Our attention to detail ensures that
                    every product we deliver is of the highest standard. We
                    strive for excellence to provide your customers with a
                    seamless and engaging user experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_why">
                  <div className="icon">
                    <i class="ri-tools-fill"></i>
                  </div>
                  <h3>Timely Delivery</h3>
                  <p>
                    We value your time and understand the importance of meeting
                    deadlines. Our team is committed to delivering projects on
                    time, without compromising on quality. With effective
                    project management and open communication, we keep you
                    informed throughout the development journey, ensuring a
                    smooth and efficient process.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_why">
                  <div className="icon">
                    <i class="ri-tools-fill"></i>
                  </div>
                  <h3>Client Satisfaction</h3>
                  <p>
                    At Beleaf Soft Tech Solutions, your satisfaction is our
                    measure of success. We foster strong and lasting
                    relationships with our clients, built on trust and
                    transparency. We go the extra mile to exceed your
                    expectations and provide ongoing support even after the
                    project is completed. Your success is our ultimate goal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* <main className="vedio_seaction">
        <section className="video-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="video-header">Centralized Crypto Exchange</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="video-paragraph">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="video-paragraph">
                  What exactly is a centralized crypto exchange? A centralized
                  cryptocurrency exchange is a digital currency trading platform
                  that is controlled by a central entity that acts as an
                  intermediary between cryptocurrency buyers and sellers.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="video-section">
          <div className="">
            <div className="image">
              <iframe
                width="933"
                height="525"
                src="https://www.youtube.com/embed/BK0fNRkVd1Y"
                title="Ask Cristiano Ronaldo Anything with Binance"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </section>
      </main> */}
      <section className="FAQS-section">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-6">
              <div id="main">
                <div class="faq-base">
                  <div class="accordion" id="faq">
                    <div class="card">
                      <h1 className="FQA-banner">Frequently Asked Question</h1>
                      <div class="card-header" id="faqhead1">
                        <a
                          href="#"
                          class="btn btn-header-link"
                          data-toggle="collapse"
                          data-target="#faq1"
                          aria-expanded="true"
                          aria-controls="faq1"
                        >
                          What industries have you worked with in the past?
                        </a>
                      </div>

                      <div
                        id="faq1"
                        class="collapse show"
                        aria-labelledby="faqhead1"
                        data-parent="#faq"
                      >
                        <div class="card-body">
                          At Beleaf Soft Tech Solutions, we have collaborated
                          with clients from various industries, including
                          e-commerce, healthcare, finance, and more. Our diverse
                          portfolio showcases our expertise in delivering
                          digital solutions tailored to meet the unique needs of
                          businesses across different sectors.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="faqhead2">
                        <a
                          href="#"
                          class="btn btn-header-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq2"
                          aria-expanded="true"
                          aria-controls="faq2"
                        >
                          How do you ensure the quality of your products?
                        </a>
                      </div>

                      <div
                        id="faq2"
                        class="collapse"
                        aria-labelledby="faqhead2"
                        data-parent="#faq"
                      >
                        <div class="card-body">
                          Quality is our top priority at Beleaf Technology. We
                          follow industry best practices and conduct rigorous
                          testing throughout the development process. Our
                          attention to detail and adherence to quality assurance
                          measures ensure that every product we deliver is of
                          the highest standard. We strive for excellence to
                          provide your customers with a seamless and engaging
                          user experience.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="faqhead3">
                        <a
                          href="#"
                          class="btn btn-header-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq3"
                          aria-expanded="true"
                          aria-controls="faq3"
                        >
                          What is your approach to project management and
                          communication?
                        </a>
                      </div>

                      <div
                        id="faq3"
                        class="collapse"
                        aria-labelledby="faqhead3"
                        data-parent="#faq"
                      >
                        <div class="card-body">
                          We understand the importance of effective project
                          management and open communication. At Beleaf
                          Technology, we value your time and strive to meet
                          deadlines without compromising on quality. Our team
                          keeps you informed throughout the development journey,
                          providing regular updates and seeking your input at
                          key stages. We believe in fostering strong
                          relationships with our clients through trust and
                          transparency, ensuring a smooth and efficient process
                          from start to finish.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contactus from "./Components/Contactus";
import Service from "./Pages/Service";
import About from "./Pages/About";
import Keedex from "./Pages/Keedex";
import Justbit from "./Pages/Justbit";
import Adverb from "./Pages/Adverb";
import Farmcex from "./Pages/Farmcex";
import Taikonz from "./Pages/Taikonz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contact from "./Pages/Contact";
import Newhome from "./Pages/Newhome";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          {/* <Route path="/" element={< Home/>} /> */}
          <Route path="/" element={<Newhome />} />

          <Route path="/Contact" element={<Contact />} />
          <Route path="/Services" element={<Service />} />
          {/* <Route path="/bitbuyerx" element={< About/>} />
      <Route path="/keedex" element={<Keedex/>} />
      <Route path="/justbit" element={<Justbit/>} />
      <Route path="/adverb" element={<Adverb/>} />
      <Route path="/farmcex" element={<Farmcex/>} />
      <Route path="/taikonz" element={<Taikonz/>} /> */}
          <Route path="/contactus" element={<Contactus />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
